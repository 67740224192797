import React from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"

import Header from "../components/sections/header"
import Features from "../components/sections/features"
import Footer from "../components/sections/footer"
import Contact from "../components/sections/contact"
import Pricing from "../components/sections/pricing"
import CookieScript from "../components/common/cookie-script"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Navigation />
    <Header />
    <Features />
    <Pricing />
    <Contact />
    <Footer />
    <CookieScript />
  </Layout>
)

export default IndexPage
