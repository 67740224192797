import React from "react"
import styled from "styled-components"

import { Container, Section } from "../global"

import { OutboundLink } from "gatsby-plugin-google-gtag"

const Pricing = () => (
<StyledSection id="pricing">
    <PricingContainer>
      <PricingTitle>Pricing based on office size</PricingTitle>
      <PackageContainer>
            <Package>
                <PackageTitle>
                    <h4>Free</h4>
                </PackageTitle>
                <PackageFeatures>
                    Free forever.
                    <h5>$0/month</h5>
                    <h5>Up to 5 desks</h5>
                </PackageFeatures>
                <PackageDivider></PackageDivider>
                <PackageFeatureList>
                    <li>Bookings & Schedules</li>
                    <li>Unlimited Team Members</li>
                    <li>Intelligent Ask Around</li>
                    <li>Custom Named Desks</li>
                    <li enabled="false">Booking Administration</li>
                    <li enabled="false">Priority Support</li>
                </PackageFeatureList>
                <ActionsContainer>
                    <OutboundLink className="slackButton" rel="noreferrer" target="_blank" href="https://slack.com/oauth/v2/authorize?client_id=1720173501458.1724787939445&scope=app_mentions:read,channels:history,channels:join,chat:write,chat:write.customize,chat:write.public,commands,groups:history,groups:write,im:history,im:write,incoming-webhook,links:read,mpim:history,mpim:write,users:read&user_scope=users:read">
                        Add to Slack
                    </OutboundLink>
                </ActionsContainer>
            </Package>

            <Package>
                <PackageTitle>
                    <h4>Startup</h4>
                </PackageTitle>
                <PackageFeatures>
                    Free for 2 months
                    <h5>$50/month</h5>
                    <h5>Up to <strong>10</strong> desks</h5>
                </PackageFeatures>
                <PackageDivider></PackageDivider>
                <PackageFeatureList>
                    <li>Bookings & Schedules</li>
                    <li>Unlimited Team Members</li>
                    <li>Intelligent Ask Around</li>
                    <li>Custom Named Desks</li>
                    <li>Booking Administration</li>
                    <li enabled="false">Priority Support</li>
                </PackageFeatureList>
                <ActionsContainer>
                    <OutboundLink className="slackButton" rel="noreferrer" target="_blank" href="https://slack.com/oauth/v2/authorize?client_id=1720173501458.1724787939445&scope=app_mentions:read,channels:history,channels:join,chat:write,chat:write.customize,chat:write.public,commands,groups:history,groups:write,im:history,im:write,incoming-webhook,links:read,mpim:history,mpim:write,users:read&user_scope=users:read">
                        Add to Slack
                    </OutboundLink>
                </ActionsContainer>
            </Package>

            <Package>
                <PackageTitle>
                    <h4>Scale Up</h4>
                </PackageTitle>
                <PackageFeatures>
                    Free for 2 months
                    <h5>$100/month</h5>
                    <h5>Up to <strong>15</strong> desks</h5>
                </PackageFeatures>
                
                <PackageDivider></PackageDivider>
                <PackageFeatureList>
                    <li>Bookings & Schedules</li>
                    <li>Unlimited Team Members</li>
                    <li>Intelligent Ask Around</li>
                    <li>Booking Administration</li>
                    <li>Custom Named Desks</li>
                    <li>Priority Support</li>
                </PackageFeatureList>
                <ActionsContainer>
                    <OutboundLink className="slackButton" rel="noreferrer" target="_blank" href="https://slack.com/oauth/v2/authorize?client_id=1720173501458.1724787939445&scope=app_mentions:read,channels:history,channels:join,chat:write,chat:write.customize,chat:write.public,commands,groups:history,groups:write,im:history,im:write,incoming-webhook,links:read,mpim:history,mpim:write,users:read&user_scope=users:read">
                        Add to Slack
                    </OutboundLink>
                </ActionsContainer>
            </Package>

            <Package>
                <PackageTitle>
                    <h4>To the Moon</h4>
                </PackageTitle>
                <PackageFeatures>
                    Free for 2 months
                    <h5>$200/month</h5>
                    <h5>Up to <strong>40</strong> desks</h5>
                </PackageFeatures>
                <PackageDivider></PackageDivider>
                <PackageFeatureList>
                    <li>Bookings & Schedules</li>
                    <li>Unlimited Team Members</li>
                    <li>Intelligent Ask Around</li>
                    <li>Booking Administration</li>
                    <li>Custom Named Desks</li>
                    <li>Priority Support</li>
                </PackageFeatureList>
                <ActionsContainer>
                    <OutboundLink className="slackButton" rel="noreferrer" target="_blank" href="https://slack.com/oauth/v2/authorize?client_id=1720173501458.1724787939445&scope=app_mentions:read,channels:history,channels:join,chat:write,chat:write.customize,chat:write.public,commands,groups:history,groups:write,im:history,im:write,incoming-webhook,links:read,mpim:history,mpim:write,users:read&user_scope=users:read">
                        Add to Slack
                    </OutboundLink>
                </ActionsContainer>
            </Package>
        </PackageContainer>
        <PackageSubline>
            If you have more than 40 desks and just downright <strong>love</strong> Simple Hot Desk, we can help you out.
            <br />
            <br />
            Get in touch with support@simplehotdesk.com and let's chat.</PackageSubline>
    </PricingContainer>
</StyledSection>
)

export default Pricing

const PackageContainer = styled.div`
display: flex;
flex-wrap: wrap;
display: flex;
flex-direction: row;
align-items: flex-start;
justify-content: center;
`

const PackageSubline = styled.div`
    width: 90%;
    text-align: center;
`

const Package = styled.div`
  background: white;
  width: 16em;
  box-sizing: border-box;
  text-align: center;
  margin: 1em;
`

const PackageTitle = styled.div`
`

const PackageFeatures = styled.div`
    box-sizing: border-box;
    h5 {
        font-size: 1.1em;
    }
    strong {
        font-family: 'HK Grotesk Bold'
    }
`

const PackageFeatureList = styled.ul`
   
    padding-inline-start: 0;
    padding-left: 0;

    li {
        list-style: none;
        margin: 0 auto;
        padding-bottom: 1em;
    }
    li[enabled="false"] {
        text-decoration: line-through;
        color: ${props => props.theme.color.black.lightest}
    }
`

const PackageDivider = styled.hr`
    width: 30%;
    border: 1px solid ${props => props.theme.color.white.darker};
`

const StyledSection = styled(Section)`
  background-color: ${props => props.theme.color.background.light};
  clip-path: polygon(0 0, 100% 5em, 100% 100%, 0% 100%);
`

const PricingContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 80px 0 40px;
`

const PricingTitle = styled.h3`
  margin: 0 auto 32px;
  text-align: center;
`

const ActionsContainer = styled.div`
  display: block;
  align-items: center;
  width: 100%;
  margin-bottom: 2em;
  margin-top: 2em;

  a { 
      font-size: 0.9em;
  }
`